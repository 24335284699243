<template>
    <div>
        <b-overlay :show="loading">
            <b-card>
                <b-row>
                    <b-col md="8">
                        <h4 class="card-title">Nueva personalización</h4>
                    </b-col>
                    <b-col md="4" class="text-right mb-2">
                        <b-button
                                variant="outline-primary"
                                @click="goList()"
                        >
                            <feather-icon
                                    icon="ArrowLeftIcon"
                                    class="mr-50"
                            />
                            Volver
                        </b-button>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        >
                            <h5 class="font-weight-bold">
                                Otec Holding
                            </h5>
                            <div class="form-label-group">
                                <v-select
                                        v-model="otecSelected"
                                        placeholder="Seleccione Otec Holding"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="name"
                                        :options="otecHolding"
                                        @input="selectOtec"
                                />
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        >
                            <h5 class="font-weight-bold">
                                Holding
                            </h5>
                            <div class="form-label-group">
                                <autocomplete-infinity
                                        url="entidades/holding"
                                        placeholder="Seleccione Holding"
                                        @item-selected="selectHolding"
                                        label="nombre_holding"
                                        id="holding"
                                        itemsPerPage="15"
                                        :params="getFiltersHoldings()"
                                ></autocomplete-infinity>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        >
                            <h5 class="font-weight-bold">
                                Empresa
                            </h5>
                            <div class="form-label-group">
                                <autocomplete-infinity
                                        url="entidades/empresa"
                                        placeholder="Seleccione Cliente"
                                        @item-selected="selectEmpresa"
                                        label="razon_social_empresa"
                                        id="id_empresa"
                                        itemsPerPage="15"
                                        :params="getFiltersEmpresas()"
                                ></autocomplete-infinity>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        >
                            <h5 class="font-weight-bold">
                                Curso
                            </h5>
                            <div class="form-label-group">
                                <b-form-group>
                                    <autocomplete-infinity
                                            url="entidades/platform-version-course"
                                            placeholder="Seleccione Curso"
                                            @item-selected="selectCurso"
                                            label="description"
                                            itemsPerPage="15"
                                            id="curso"
                                            :params="getFiltersCourses()"
                                    ></autocomplete-infinity>

                                </b-form-group>
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col md="12" class="mb-1 d-none">
                        <b-form-checkbox
                                v-model="byDefault"
                                class="custom-control-primary"
                        >
                            Por defecto
                        </b-form-checkbox>
                    </b-col>
                    <b-col  md="6">
                        <v-select
                                v-model="selectImg"
                                :options="itemsImg"
                                label="nombre"
                                placeholder="Seleccione Imagen"
                                @input="selectImage"
                        >
                            <template slot="option" slot-scope="option">
                                <b-avatar square :src="option.image"></b-avatar>
                                {{ option.nombre }}
                            </template>
                        </v-select>
                    </b-col>
                    <b-col md="6" class="d-none">
                        <b-form-group
                        >
                            <h5 class="font-weight-bold">
                                Imagen de fondo del Certificado
                            </h5>
                            <div class="form-label-group">

                                <div class="d-flex mb-3">
                                    <b-form-file
                                            v-model="image"
                                            placeholder="Seleccione un archivo o suéltelo aquí..."
                                            drop-placeholder="Suelte el archivo aquí..."
                                            class="w-auto flex-grow-1"
                                            accept="image/*"
                                            browse-text="Seleccione"
                                    />
                                    <b-button v-if="hasImage" variant="danger" class="ml-3" @click="clearImage">Eliminar</b-button>
                                </div>
                                <b-img v-if="hasImage" :src="imageSrc" class="mb-3" fluid block rounded></b-img>

                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" >
                        <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                type="submit"
                                @click="addImg">
                            <feather-icon icon="ImageIcon" class="mr-50"/>
                            <span class="align-middle">Subir Nueva Imagen</span>
                        </b-button>
                    </b-col>
                    <b-col md="6" class="mt-2">
                        <b-img v-if="hasImage" :src="imageSrc" class="mb-3" fluid block rounded></b-img>

                    </b-col>
                    <b-col md="12" class="text-center mb-2 mt-2 etiquetas">
                        <h3>Etiquetas</h3>
                        <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(nombreEstudiante)')"
                        >
                            nombreEstudiante
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(nota)')"
                        >
                            nota
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(fraseCertificado)')"
                        >
                            fraseCertificado
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(nombreCurso)')"
                        >
                            nombreCurso
                        </b-button>
                      <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(modalidad)')"
                        >
                          modalidad
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(fechaInicio)')"
                        >
                            fechaInicio
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(fechaTermino)')"
                        >
                            fechaTermino
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(horasCurso)')"
                        >
                            horasCurso
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                                variant="outline-dark"
                                pill
                                @click="addEtiqueta('(id_detalle_alumno)')"
                        >
                            id_detalle_alumno
                        </b-button>
                    </b-col>
                    <b-col md="12">
                        <div class="d-none">{{urlCertificado}}{{response}}</div>
                        <quill-editor
                                v-model="certificateDetail"
                                ref="editor"
                        />
                    </b-col>
                    <b-col md="12" class="text-right mt-2">
                        <b-button
                                variant="outline-primary"
                                class="mr-1"
                                @click="generarCertificadoDemo"
                        >
                            Generar Certificado Demo
                        </b-button>
                        <b-button
                                variant="outline-primary"
                                @click="guardar"
                        >
                            Guardar
                        </b-button>
                    </b-col>

                </b-row>
            </b-card>
            <b-modal
                id="modal-image"
                ref="modal_img"
                title="Imágenes"
                ok-title="Cerrar"
                size="lg"
                ok-only
            >
                <imagenes></imagenes>
            </b-modal>
        </b-overlay>
    </div>
</template>

<script>  import Ripple from "vue-ripple-directive";
const base64Encode = data =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
import {
    BRow,
    BCol,
    BFormFile,
    BImg,
    BButton,
    BOverlay,
    VBTooltip,
    BAvatar
} from 'bootstrap-vue'
import imagenes from '@/views/imagenes/Listar.vue'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill';
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "Agregar", components: {
        BRow,
        BCol,
        quillEditor,
        BFormFile,
        BImg,
        BButton,
        BOverlay,
        Quill,
        'imagenes':imagenes,
        BAvatar,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data: () => ({
        image: null,
        imageSrc: null,
        imageDelete: false,
        porDefecto: false,
        uploadedFiles: [],
        holding: null,
        empresa: null,
        otec: null,
        curso: null,
        byDefault: true,
        certificateDetail: `<div id="certificate_detail" style="text-align: center;">
                                <h2>La Gerencia de nuestra OTEC hace entrega del presente Diploma a:</h2>
                                <h3><strong>(nombreEstudiante)</strong></h3>
                                <h3>(fraseCertificado)</h3>
                                <h3>(nombreCurso)</h3>
                                <h3>Realizado en Santiago entre (fechaInicio) y el (fechaTermino)</h3>
                                <h3>con un total de (horasCurso) horas</h3>
                                <h3>Código de validez  (id_detalle_alumno)</h3>
                            </div>`,
        url: null,
        urlPdf: null,
        otecSelected: null,
        cursoSelected: null,
        empresaSelected: null,
        holdingSelected: null,
        selectImg: null,
        idImg: null,

    }),
    computed: {
        itemsImg() {
            return this.$store.state.imagenes.list;
        },
        hasImage() {
            return !!this.imageSrc;
        },
        editor() {
            return this.$refs.editor.quill
        },
        loading() {
            return this.$store.state.gestionarCertificados.loading;
        },
        otecHolding() {
            return this.$store.state.gestionarCertificados.otecHolding;
        },
        response() {
            let response = this.$store.state.gestionarCertificados.response;
            if(response == 'success' ){
                this.$router.push({ name: "gestionar_certificados" });
                this.showToast('success','El certificado se ha creado correctamente!!')
                this.$store.commit('gestionarCertificados/setResponse', null)
                this.$store.commit('gestionarCertificados/setUrlImage', null)
            }
            return response
        },
        urlCertificado() {
            if(this.urlPdf !== this.$store.state.gestionarCertificados.url)
            {
                this.urlPdf = this.$store.state.gestionarCertificados.url
                window.open(this.urlPdf , '_blank') //to open in new tab
                this.$store.commit('gestionarCertificados/setUrl', null)
            }
            return this.urlPdf;
        },
        /*urlImage() {
            if(this.$store.state.gestionarCertificados.urlImage !== null && !this.imageDelete){
                this.imageSrc = this.$store.state.gestionarCertificados.urlImage
                var arrUrl = String(this.imageSrc).split('/');
                this.url = arrUrl[arrUrl.length - 1];
            }
            return this.imageSrc;
        },*/
    },
    watch: {
        image(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then(value => {
                            this.imageSrc = value;
                            let file = {'file': [this.image]}
                            this.$store.dispatch('gestionarCertificados/uploadFileImage', file)
                        })
                        .catch(() => {
                            this.imageSrc = null;
                        });
                } else {
                    this.imageSrc = null;
                }
            }
        }
    },
    mounted() {
        this.getOtecHolding()
        this.getListImagenes()
    },
    methods: {
        showToast(variant, mensaje) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Notificación',
                    icon: 'BellIcon',
                    text: mensaje,
                    variant,
                },
            })
        },
        addImg(){
            this.$refs.modal_img.show();
        },
        selectImage(img){
            if(img != null){
            this.idImg = img.id;
            this.imageSrc = img.image;
            if(this.imageSrc != null){
                var arrUrl = String(this.imageSrc).split('/');
                this.url = arrUrl[arrUrl.length - 1];
            }
            }else {
                this.imageSrc = null;
                this.selectImg = null;
                this.idImg = null;
                this.url = null;
            }

        },
        getListImagenes() {
            this.$store.dispatch('imagenes/getListFilter')
        },
        selectHolding(holding) {
            this.holding = holding != null ? holding.id_holding : null;
            this.holdingSelected = holding
        },
        selectEmpresa(empresa) {
            this.empresa = empresa != null ? empresa.id_empresa : null;
            this.empresaSelected = empresa
        },
        selectCurso(curso) {
            this.curso = curso != null ? curso.id_platform_version_course : null;
            this.cursoSelected = curso
        },
        selectOtec(otec) {
            this.otec = otec != null ? otec.id_otec_holding : null;
            this.otecSelected = otec
        },
        getFiltersHoldings(){
            return {
                "empresa" :this.empresa,
                "otec" :this.otec,
                "curso" :this.curso,
            }
        },
        getFiltersCourses(){
            return {
                "empresa" :this.empresa,
                "otec" :this.otec,
                "holding" :this.holding,
            }
        },
        getFiltersEmpresas(){
            return {
                "holding" :this.holding,
                "otec" :this.otec,
                "curso" :this.curso,
            }
        },
        clearImage() {
            this.image = null
            this.imageSrc = null
            this.imageDelete = true
        },
        addEtiqueta(etiqueta) {
            const selection = this.editor.getSelection();
            this.editor.clipboard.dangerouslyPasteHTML(selection.index, etiqueta);
        },
        convertirCertificateDetail(){
            let cadena = String(this.certificateDetail)
            cadena = cadena.replace('<div id="certificate_detail" style="text-align: center;">', '')
            cadena = cadena.replace('</div>', '')
            cadena = btoa(unescape(encodeURIComponent(cadena)))

            return cadena
        },
        generarCertificadoDemo() {
            this.showToast('info','Se está generando el certificado Demo. Por favor, espere...')
            let data_certificado = {
                'validationCode': 100001,
                'student' :
                    {
                        'name': 'Test Test Test1',
                        'email': 'test@email.com'
                    },
                'course': {
                    'name': 'Curso Test11',
                    'description': 'Curso Tes11t',
                    'duration': '150',
                    'modality': 'A Distancia',
                },
                'grade': 7,
                'init_date': '2020-12-12',
                'end_date': '2020-12-12',
                'message': 'Por haber Cursado y Aprobado el Curso',
                'num_ficha': '000000',
                'id_holding': '3333',
                'nombre_holding': 'Test Holding',
                'id_empresa': '1',
                'certificate': {
                    'url': this.url,
                    'certificate_detail': this.convertirCertificateDetail() ,
                    'message': 'Por haber Cursado y Aprobado el Curso',
                }
            }

            this.$store.dispatch('gestionarCertificados/generarCertificadoDemo',data_certificado)
        },
        guardar() {
            if (this.idImg == null){
                this.showToast('warning','Debe seleccionar una image de fondo para el Certificado')
                return
            }
            if (this.certificateDetail == null){
                this.showToast('warning','Debe escribir el detalle del Certificado')
            }
            if((this.curso !== null || this.holding !== null || this.empresa !== null || this.otec !== null)){
                let data = {
                    id_course: this.curso !== null ? this.curso : '',
                    id_holding: this.holding !== null ? this.holding : '',
                    id_empresa: this.empresa !== null ? this.empresa : '',
                    otec_holding_id: this.otec !== null ? this.otec : '',
                    by_default: this.byDefault ? 1 : 0,
                    url: this.url,
                    id_image: this.idImg,
                    type_of_certificate: 1,
                    state: 'active',
                    certificate_detail: this.convertirCertificateDetail(),
                }
                this.$store.dispatch('gestionarCertificados/insertarCertificado',data)
            }else {
                const h = this.$createElement
                const vNodesMsg = h(
                    'p',
                    {class: ['text-left', 'mb-0']},
                    [
                        ` Uno de estos datos es obligatorio para crear la personalización: `,
                        h('strong', 'Holding'),
                        `, `,
                        h('strong', 'Empresa'),
                        `, `,
                        h('strong', 'Otec Holding'),
                        ` ó `,
                        h('strong', 'Curso'),
                    ]
                )
                this.$bvToast.toast(vNodesMsg, {
                    title: `ALERTA!!`,
                    variant: 'warning',
                    solid: true
                });
            }
        },
        goList(){
            this.$router.push({ name: "gestionar_certificados" });
        },
        getOtecHolding() {
            let filterData = {
                "empresa" :this.empresa,
                "holding" :this.holding,
                "curso" :this.curso,
            }
            this.$store.dispatch('gestionarCertificados/getOtecHolding', filterData)
        },

    },
}
</script>

<style lang="scss" scoped>
    .etiquetas .btn{
        padding: 0.386rem 0.5rem;
        margin-left: 0.3rem;
    }
</style>

